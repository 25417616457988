/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "./layout.css"

const HeadStyle = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://fonts.venn.city/mark-pro-font.css"
      ></link>
    </Helmet>
  )
}

const Layout = ({ children }) => (
  <>
    <HeadStyle />
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <div className="contentLayout">
            <main>{children}</main>
          </div>
        </>
      )}
    />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
